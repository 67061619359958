import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/user/src/app/app/[locale]/(root)/_components/CookieConsent/index.tsx");
;
import(/* webpackMode: "eager" */ "/user/src/app/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/lib/ga4.tsx");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"400\",\"600\",\"700\"]}],\"variableName\":\"nunitoSans\"}");
